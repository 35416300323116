import React from 'react'
import PropTypes from 'prop-types'
import styled, { css, useTheme } from 'styled-components'
import { AnimatePresence, motion } from 'framer-motion'
import { Col, Row } from 'react-flexa'
import { isEqual } from 'lodash'

import { backgroundTransition } from '../../style/animation'
import { useAppContext } from '../../context/AppContext'

const BackgroundGrid = ({}) => {
  const {
    media: { isLarge },
    image: { image, imageLocation },
    gallery: { openGallery },
  } = useAppContext()
  const theme = useTheme()
  let pattern = theme.layout.pattern.xs
  if (isLarge) pattern = theme.layout.pattern.lg
  return (
    <Background transition={backgroundTransition} animate={{ backgroundColor: theme.color.background }}>
      <StyledRow justifyContent="center" alignContent="flex-start">
        {pattern.map((r, ri) => {
          return r.map((v, c) => {
            const imgPlateSrc = isEqual(imageLocation, [ri, c]) && image
            return (
              <StyledCol key={`${r}${v}${c}`} xs={6} lg={4} interactive={!!imgPlateSrc} onClick={openGallery}>
                <ColorBlock
                  transition={backgroundTransition}
                  animate={{ opacity: v, backgroundColor: theme.color.primary }}
                />
                <AnimatePresence>
                  {!!imgPlateSrc && (
                    <ImagePlate
                      src={imgPlateSrc}
                      transition={backgroundTransition}
                      initial={{ opacity: 0 }}
                      exit={{ opacity: 0 }}
                      animate={{ opacity: (isLarge ? 0.15 : 0.6) + v }}
                      whileHover={{ opacity: 0.8 }}
                      whileTap={{ opacity: 0.8 }}
                    />
                  )}
                </AnimatePresence>
              </StyledCol>
            )
          })
        })}
      </StyledRow>
    </Background>
  )
}

BackgroundGrid.propTypes = {}

export default BackgroundGrid

const ImagePlate = styled(motion.img)`
  ${({ theme }) => css`
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: saturate(0);
    position: absolute;
    top: 0;
    left: 0;
    translate(-50%, -50%);
  `}
`

const ColorBlock = styled(motion.div)`
  ${({ theme }) => css`
    //pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
  `}
`

const Background = styled(motion.div)`
  ${({ theme }) => css`
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: black;
    z-index: 1;
    min-height: 680px;
  `}
`

const StyledRow = styled(Row)`
  ${({ theme }) => css`
    overflow: hidden;
    height: 100%;
  `}
`

const StyledCol = styled(Col)`
  ${({ theme, height, interactive }) => css`
    height: ${height || '33%'};
    position: relative;
    pointer-events: ${interactive ? 'auto' : 'none'};
    cursor: ${interactive ? 'pointer' : 'auto'};

    ${theme.media.lg`
      height: ${height || '50%'};
    `}
  `}
`
