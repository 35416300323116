import { em, stripUnit, rem } from 'polished'
import { css } from 'styled-components/macro'
import { mapValues, get } from 'lodash'
import Bowser from 'bowser'
import { COLOR_BLACK, COLOR_WHITE, DARK_MODE, LIGHT_MODE } from '../constants'

export let device = { mobile: false }
export let isMobile = false

export const initDeviceInfo = () => {
  if (typeof window !== 'undefined') {
    device = Bowser.getParser(window.navigator.userAgent)
    const type = get(device, 'parsedResult.platform.type')
    isMobile = device.mobile = type === 'mobile' || type === 'tablet'
    isMobile = device.mobile = type === 'mobile' || type === 'tablet'
  }
}

// px
export const breakpointsPx = {
  xs: 0,
  sm: 321,
  md: 480,
  lg: 769,
  xl: 1025,
  xxl: 1680,
}

// em
export const breakpoints = mapValues(breakpointsPx, (value) => stripUnit(em(value)))

export const color = {
  [DARK_MODE]: {
    primary: '#ffffff',
    background: '#000000',
    headerPrimary: '#BFBFBF',
    headerBackground: '#000000',
    links: '#ffffff',
  },
  [LIGHT_MODE]: {
    primary: '#000000',
    background: '#ffffff',
    headerPrimary: '#111111',
    headerBackground: '#ffffff',
    links: '#000000',
  },
}

// px
export const fontSizes = {
  xs: 18, // h6
  sm: 18, // h5
  md: 18, // p
  lg: 27, // h4
  xl: 34, // h3
  xxl: 44, // h2
  xxxl: 54, // h1
}

const spacingBase = 10
export const space = (multipier = 2) => rem(spacingBase * multipier)

export const media = Object.keys(breakpoints).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (min-width: ${breakpoints[label]}em) {
      ${css(...args)};
    }
  `
  return acc
}, {})

const sizes = css`
  ${media.lg`
    max-width: ${rem(950)};
  `}
`

export const patterns = {
  [DARK_MODE]: {
    xs: [
      [0.1, 0.05],
      [0.05, 0.025],
      [0.025, 0],
    ],
    lg: [
      [0.05, 0.025, 0.015],
      [0.1, 0.05, 0],
    ],
  },
  [LIGHT_MODE]: {
    xs: [
      [0.015, 0.025, 0.05],
      [0, 0.05, 0.1],
    ],
    lg: [
      [0.015, 0.025, 0.05],
      [0, 0.05, 0.1],
    ],
  },
}
export const layout = {
  spacingBase,
  sizes,
  headerHeight: 125,
}

const zeroGutter = {
  xs: 0, // rem
  sm: 0, // rem
  md: 0, // rem
  lg: 0, // rem
  xl: 0, // rem
  xxl: 0, // rem
}

const defaultTheme = {
  breakpointsPx,
  device,
  fontSizes,
  layout,
  media, // media queries
  space,
  flexa: {
    colums: 6,
    gutter: {
      xs: 0, // rem
      sm: 0, // rem
      md: 0, // rem
      lg: 0, // rem
      xl: 0, // rem
      xxl: 0, // rem
    },
    breakpoints,
  },
}

export const themes = {
  [DARK_MODE]: {
    ...defaultTheme,
    color: color[DARK_MODE],
    layout: {
      ...layout,
      pattern: patterns[DARK_MODE],
    },
  },
  [LIGHT_MODE]: {
    ...defaultTheme,
    color: color[LIGHT_MODE],
    layout: {
      ...layout,
      pattern: patterns[LIGHT_MODE],
    },
  },
}

export const lightTheme = {
  breakpointsPx,
  color,
  device,
  fontSizes,
  layout,
  media, // media queries
  space,
  flexa: {
    colums: 6,
    gutter: {
      xs: 0, // rem
      sm: 0, // rem
      md: 0, // rem
      lg: 0, // rem
      xl: 0, // rem
      xxl: 0, // rem
    },
    breakpoints,
  },
}
