import React, { Suspense, useEffect } from 'react'
import PropTypes from 'prop-types'
import GlobalStyle from '../../style/global'
import MediaQueryDebug from '../common/MediaQueryDebug'
import AppProvider from '../../context/AppContext'
import SEO from '../SEO'
import BackgroundGrid from '../common/BackgroundGrid'
import Header from '../common/Header'
import Theme from './Theme'
import useSSR from 'use-ssr/dist/useSSR'

const GalleryModal = React.lazy(() => import('../gallery/GalleryModal'))

const Layout = ({ children, pageContext, location }) => {
  const seo = pageContext.seo?.globalSeo?.fallbackSeo

  const { isBrowser } = useSSR()

  return (
    <>
      <AppProvider location={location}>
        <Theme>
          <GlobalStyle />
          <SEO data={seo} />
          {/*<Suspense fallback={<></>}>*/}
          {/*  <Inertia />*/}
          {/*</Suspense>*/}
          <BackgroundGrid />
          <Header location={location} />
          {children}
          <MediaQueryDebug />
          {isBrowser && (
            <Suspense fallback={<></>}>
              <GalleryModal />
            </Suspense>
          )}
        </Theme>
      </AppProvider>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.any.isRequired,
  pageContext: PropTypes.object.isRequired,
  location: PropTypes.object,
}

export default Layout
