import React, { createContext, useContext, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import useColorMode from '../hooks/useColorMode'
import useMedia from 'use-media'
import { breakpointsPx } from '../style/theme'
import useToggle from '../hooks/useToggle'

export const AppContext = createContext(null)

const AppProvider = ({ children, location }) => {
  const pathname = useMemo(() => location.pathname, [location])

  const [{ x, y, distance, angle }, setTelemetry] = useState(0)
  const [image, setImage] = useState(0)
  const [imageLocation, setImageLocation] = useState([1, 0])

  const [galleryOpen, , openGallery, closeGallery] = useToggle(false)
  const [galleryContent, setGalleryContent] = useState([])

  const { colorMode, setColorMode } = useColorMode(pathname)

  // Breakpoints
  const isMedium = useMedia(`(min-width: ${breakpointsPx.md}px)`)
  const isLarge = useMedia(`(min-width: ${breakpointsPx.lg}px)`)
  const isXLarge = useMedia(`(min-width: ${breakpointsPx.xl}px)`)
  const isXXLarge = useMedia(`(min-width: ${breakpointsPx.xxl}px)`)

  useEffect(() => {
    if (isLarge) setImageLocation([1, 1])
    else setImageLocation([1, 0])
  }, [isLarge])

  const value = useMemo(
    () => ({
      colorMode,
      setColorMode,
      location,
      gallery: {
        galleryOpen,
        openGallery,
        closeGallery,
        galleryContent,
        setGalleryContent,
      },
      image: {
        imageLocation,
        setImageLocation,
        setImage,
        image,
      },
      telemetry: {
        x,
        y,
        distance,
        angle,
        setTelemetry,
      },
      media: {
        isMedium,
        isLarge,
        isXLarge,
        isXXLarge,
      },
    }),
    [
      angle,
      closeGallery,
      colorMode,
      distance,
      galleryContent,
      galleryOpen,
      image,
      imageLocation,
      isLarge,
      isMedium,
      isXLarge,
      isXXLarge,
      location,
      openGallery,
      setColorMode,
      x,
      y,
    ],
  )
  return <AppContext.Provider value={value}>{children}</AppContext.Provider>
}

AppProvider.propTypes = {
  children: PropTypes.node,
  location: PropTypes.object,
}

export const useAppContext = () => useContext(AppContext)

export default AppProvider
