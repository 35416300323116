import { useCallback, useEffect, useState } from 'react'
import { DARK_MODE, LIGHT_MODE, MODES_BY_PATH_PATTERN } from '../constants'

export default (pathname, defaultMode = DARK_MODE) => {
  const [colorMode, setColorMode] = useState(defaultMode)

  useEffect(() => {
    if (pathname) {
      //setColorMode(MODES_BY_PATH_PATTERN[pathname] || DARK_MODE)
    }
  }, [pathname])

  return {
    setColorMode,
    colorMode,
  }
}
