export const SHARE_MESSAGE = 'shareMessage'
export const SITE_URL = 'https://www..com'

export const COLOR_WHITE = 1
export const COLOR_BLACK = 0

export const DARK_MODE = 0
export const LIGHT_MODE = 1

export const MODES_BY_PATH_PATTERN = {
  '/': DARK_MODE,
  '/projects': DARK_MODE,
}

export const IS_DEV = process.env.GATSBY_SITE_IS_DEV === 'true'
