import { createGlobalStyle } from 'styled-components/macro'
import fonts from './fonts'
import { css } from 'styled-components'
const GlobalStyle = createGlobalStyle`
  ${({ theme }) => css`
    ${fonts};
    html,
    body,
    div,
    span,
    applet,
    object,
    iframe,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    blockquote,
    pre,
    a,
    abbr,
    acronym,
    address,
    big,
    cite,
    code,
    del,
    dfn,
    em,
    img,
    ins,
    kbd,
    q,
    s,
    samp,
    small,
    strike,
    strong,
    sub,
    sup,
    tt,
    var,
    b,
    u,
    i,
    center,
    dl,
    dt,
    dd,
    ol,
    ul,
    li,
    fieldset,
    form,
    label,
    legend,
    table,
    caption,
    tbody,
    tfoot,
    thead,
    tr,
    th,
    td,
    article,
    aside,
    canvas,
    details,
    embed,
    figure,
    figcaption,
    footer,
    header,
    hgroup,
    menu,
    nav,
    output,
    ruby,
    section,
    summary,
    time,
    mark,
    audio,
    video {
      margin: 0;
      padding: 0;
      border: 0;
      font-size: 100%;
      font: inherit;
      vertical-align: baseline;
    }
    /* HTML5 display-role reset for older browsers */
    article,
    aside,
    details,
    figcaption,
    figure,
    footer,
    header,
    hgroup,
    menu,
    nav,
    section {
      display: block;
    }

    html,
    body {
      font-size: 16px;
    }

    html {
      width: 100%;
      height: 100%;
    }
    body {
      line-height: 1;
      margin: 0;
      padding: 0;
      font-family: sans-serif;
      min-height: 680px;
      height: 100%;
      background-color: ${theme.color.background};
      background-repeat: repeat;
      color: white;
      ${theme.media.md`
        min-height: 100%;
      `}
    }
    ol,
    ul {
      list-style: none;
    }
    blockquote,
    q {
      quotes: none;
    }
    blockquote:before,
    blockquote:after,
    q:before,
    q:after {
      content: '';
      content: none;
    }
    table {
      border-collapse: collapse;
      border-spacing: 0;
    }

    * {
      box-sizing: border-box;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      user-select: none;
      -webkit-user-drag: none;
    }

    a,
    a:hover,
    a:visited {
      text-decoration: none;
      color: ${theme.color.links};
    }

    #___gatsby {
      width: 100%;
      height: 100%;
      position: relative;
      > div {
        width: 100%;
        min-height: 100%;
      }
    }
    .arrow {
      width: 30px;
      height: 30px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      -webkit-transform: translateY(-50%);
      cursor: pointer;
    }

    .arrow--left {
      left: 5px;
    }

    .arrow--right {
      left: auto;
      right: 5px;
    }

    .arrow--disabled {
      opacity: 0.2;
    }
  `}
  
  
`

export default GlobalStyle
