import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { get, isNumber } from 'lodash'
import { rem, em } from 'polished'
import { fontSizes } from '../../style/theme'
import { motion } from 'framer-motion'

export const sizes = fontSizes
const fontKeys = Object.keys(fontSizes)

export const setFontSize = (size, ratio) => css`
  font-size: ${rem(size)};

  ${ratio &&
    css`
      line-height: ${ratio};
    `};
`

const setSize = (size, ratio) => (isNumber(size) ? setFontSize(size, ratio) : setFontSize(fontSizes[size], ratio))

const Text = styled.div`
  ${({
    center,
    color,
    ellipsis,
    lineHeight,
    lineHeightMultiplier,
    light,
    thin,
    nowrap,
    opacity,
    right,
    justify,
    selectNone,
    size,
    spacing,
    serif,
    theme,
    shadow,
    uppercase,
    xs,
    sm,
    md,
    lg,
    xl,
    xxl,
    inline,
    bold,
    medium,
    italic,
  }) => css`
    position: relative;

    font-family: 'Lato', sans-serif;
    font-weight: 400;
    ${setSize(size, lineHeightMultiplier)};

    text-align: left;
    color: ${theme.color.primary};
    margin: 0;
    z-index: 1;

    /* Override */
    
    
    
    ${serif &&
      css`
        font-family: 'Libre Caslon Display', serif;
      `}
    
   ${bold &&
     css`
       font-weight: bold;
     `}
   ${medium &&
     css`
       font-weight: 500;
     `}
   
   ${italic &&
     css`
       font-style: oblique;
     `}
    
   ${light &&
     css`
       font-weight: 300;
     `}
   
   ${thin &&
     css`
       font-weight: 100;
     `}
    
    ${inline &&
      css`
        display: inline;
      `}


    ${shadow &&
      css`
        text-shadow: 0px 2px 32px rgba(0, 0, 0, 0.7);
      `}

    ${center &&
      css`
        text-align: center;
      `};

    ${color &&
      css`
        color: ${get(theme.color, color) || color};
      `};

    ${ellipsis &&
      css`
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      `};

    ${nowrap &&
      css`
        white-space: nowrap;
      `};

    ${opacity &&
      css`
        opacity: ${opacity};
      `};

    ${lineHeight &&
      css`
        line-height: ${lineHeight};
      `};

    ${right &&
      css`
        text-align: right;
      `};
    
     ${justify &&
       css`
         text-align: justify;
       `};

    ${selectNone &&
      css`
        user-select: none;
      `};

    ${spacing &&
      css`
        letter-spacing: ${!isNaN(spacing) ? rem(spacing) : spacing};
      `};

    ${uppercase &&
      css`
        text-transform: uppercase;
      `};

    /* Media queries */

    ${xs &&
      theme.media.xs`
        ${setSize(xs, lineHeightMultiplier)};
      `};

    ${sm &&
      theme.media.sm`
        ${setSize(sm, lineHeightMultiplier)};
      `};

    ${md &&
      theme.media.md`
         ${setSize(md, lineHeightMultiplier)};
      `};

    ${lg &&
      theme.media.lg`
         ${setSize(lg, lineHeightMultiplier)};
      `};

    ${xl &&
      theme.media.xl`
        ${setSize(xl, lineHeightMultiplier)};
      `};

    ${xxl &&
      theme.media.xxl`
        ${setSize(xxl, lineHeightMultiplier)};
      `};

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
    }
  `}
`

Text.propTypes = {
  bold: PropTypes.bool,
  center: PropTypes.bool,
  children: PropTypes.node,
  color: PropTypes.string,
  ellipsis: PropTypes.bool,
  lineHeight: PropTypes.number,
  lineHeightMultiplier: PropTypes.number,
  linkColor: PropTypes.string,
  nowrap: PropTypes.bool,
  right: PropTypes.bool,
  selectNone: PropTypes.bool,
  slab: PropTypes.bool,
  helv: PropTypes.bool,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf(fontKeys)]),
  spacing: PropTypes.oneOfType([PropTypes.number, PropTypes.string]), // rem || 'normal'
  theme: PropTypes.object,
  white: PropTypes.bool,
  uppercase: PropTypes.bool,
  shadow: PropTypes.bool,
  inline: PropTypes.bool,
  xs: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf(fontKeys)]),
  sm: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf(fontKeys)]),
  md: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf(fontKeys)]),
  lg: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf(fontKeys)]),
  xl: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf(fontKeys)]),
}

Text.defaultProps = {
  lineHeightMultiplier: 1,
  size: fontSizes.md,
}

const headingProps = {
  lineHeightMultiplier: 1.2,
  bold: true,
  uppercase: true,
}

Text.h1 = Text.withComponent('h1')
Text.h1.defaultProps = {
  ...headingProps,
  lineHeightMultiplier: 54 / 46,
  size: 54 / 2,
  md: 37,
  xxl: 'xxxl',
  uppercase: true,
  spacing: em(-0.07),
  slab: true,
}

Text.h2 = Text.withComponent('h2')
Text.h2.defaultProps = {
  ...headingProps,
  lineHeightMultiplier: 1.24,
  lineHeightLg: 1.3125,
  size: 44 / 2,
  md: 31,
  lg: 31,
  xxl: 44,
  uppercase: true,
  spacing: em(-0.07),
}

Text.h3 = Text.withComponent('h3')
Text.h3.defaultProps = {
  ...headingProps,
  lg: 'xl',
  lineHeightMultiplier: 1.35,
  spacing: 1.5,
}

Text.h4 = Text.withComponent('h4')
Text.h4.defaultProps = {
  ...headingProps,
  bold: true,
  lg: 'lg',
  spacing: 1.37,
  lineHeightMultiplier: 1.3125,
}

Text.h5 = Text.withComponent('h5')
Text.h5.defaultProps = {
  ...headingProps,
  bold: false,
  medium: true,
  lg: 'sm',
  spacing: 1.2,
  lineHeightMultiplier: 1.5,
  lineHeightLg: 1.6,
}

Text.h6 = Text.withComponent('h6')
Text.h6.defaultProps = {
  ...headingProps,
  bold: true,
  size: 'xs',
  lineHeightMultiplier: 1.32,
  spacing: 0.5,
}

Text.p = Text.withComponent('p')
Text.p.defaultProps = {
  ...Text.defaultProps,
}

Text.a = Text.withComponent('a')
Text.a.defaultProps = {
  ...Text.defaultProps,
}

Text.motion = Text.withComponent(motion.div)
Text.motion.defaultProps = {
  ...Text.defaultProps,
}

Text.motion.h1 = Text.withComponent(motion.h1)
Text.motion.h1.defaultProps = {
  ...Text.defaultProps,
}

Text.motion.h2 = Text.withComponent(motion.h2)
Text.motion.h2.defaultProps = {
  ...Text.defaultProps,
}

Text.span = Text.withComponent('span')

export default Text
