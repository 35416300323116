import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { motion } from 'framer-motion'

const Eye = ({ animate, transition, initial }) => {
  return (
    <Svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 161.4 95.3">
      <motion.path
        fill="#000000"
        initial={initial}
        animate={animate}
        transition={transition}
        d="M81,75.5c-15.4,0-27.9-12.5-27.9-27.9S65.5,19.7,81,19.7s27.9,12.5,27.9,27.9S96.4,75.5,81,75.5z"
      />
      <motion.path
        fill="#000000"
        initial={initial}
        animate={animate}
        transition={transition}
        d="M81,0C52.4,0,0,28,0,47.6s52,47.6,80.4,47.6c28.6,0,81-28,81-47.6S109.4,0,81,0z M80.4,85.6c-27.3,0-70.7-27.3-70.7-38
	s43.7-38,71.3-38c27.4,0,70.8,27.3,70.8,38S108,85.6,80.4,85.6z"
      />
    </Svg>
  )
}

Eye.propTypes = {
  animate: PropTypes.object,
  transition: PropTypes.object,
}

const Svg = styled.svg(
  ({ theme }) => css`
    height: 100%;
    width: auto;
    position: relative;
  `,
)

export default Eye
