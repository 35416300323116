import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { motion } from 'framer-motion'

const Emblem = ({ animate, transition, initial }) => {
  return (
    <Svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 106.3 125">
      <motion.path
        fill="#000000"
        animate={animate}
        initial={initial}
        transition={transition}
        d="M0.9,0v1.3h57L0.4,81.4c-0.1,0.2-0.2,0.4-0.3,0.5C0,82,0,82.2,0,82.4v0.2h59.7l0.1-1.3H2L59.5,1.4
	C59.8,1,60,0.6,60,0.2V0H0.9z"
      />
      <motion.path
        fill="#000000"
        animate={animate}
        initial={initial}
        transition={transition}
        d="M99,41.2c-4.8-3.9-11.7-5.8-20.7-5.8H59.6V125h1.4V82.6h17.2c4.1,0,7.9-0.6,11.3-1.8c3.4-1.2,6.4-2.8,8.9-4.9
	c2.5-2.1,4.4-4.6,5.8-7.5c1.4-2.9,2-6.1,2-9.5C106.3,51,103.9,45.1,99,41.2z M103,67.8c-1.3,2.7-3,5.1-5.3,7.1
	c-2.3,2-5.1,3.6-8.4,4.8c-3.3,1.2-6.9,1.7-11,1.7H61.1V36.5h17.2c8.9,0,15.5,1.9,19.9,5.7c4.4,3.8,6.6,9.4,6.6,16.7
	C104.9,62.1,104.2,65,103,67.8z"
      />
    </Svg>
  )
}

Emblem.propTypes = {
  animate: PropTypes.object,
  transition: PropTypes.object,
}

const Svg = styled.svg(
  ({ theme }) => css`
    height: 100%;
    width: auto;
    position: relative;
  `,
)

export default Emblem
