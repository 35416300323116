import React from 'react'
import PropTypes from 'prop-types'
import styled, { css, ThemeProvider } from 'styled-components'
import { themes } from '../../style/theme'
import AppProvider, { useAppContext } from '../../context/AppContext'

const Theme = ({ children }) => {
  const { colorMode } = useAppContext()
  return <ThemeProvider theme={themes[colorMode]}>{children}</ThemeProvider>
}

Theme.propTypes = {
  children: PropTypes.any,
}

export default Theme
