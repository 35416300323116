import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import styled, { css, useTheme } from 'styled-components'
import Emblem from './Emblem'
import { rem } from 'polished'
import Text from './Text'
import Eye from './Eye'
import { useAppContext } from '../../context/AppContext'
import { DARK_MODE } from '../../constants'
import { motion } from 'framer-motion'
import { backgroundTransition } from '../../style/animation'
import { Link } from 'gatsby'

const Header = ({ location }) => {
  const theme = useTheme()

  const animations = {
    emblem: {
      fill: theme.color.headerPrimary,
      opacity: 1,
    },
    eye: {
      fill: theme.color.headerBackground,
      opacity: 1,
    },
    strap: {
      backgroundColor: theme.color.headerPrimary,
      opacity: 1,
    },
    text: {
      color: theme.color.headerPrimary,
      opacity: 1,
    },
  }
  return (
    <Container>
      <Strap>
        <Line initial={{ opacity: 0 }} animate={animations.strap} transition={backgroundTransition} />
        <Line initial={{ opacity: 0 }} animate={animations.strap} transition={backgroundTransition}>
          <Eye initial={{ opacity: 0 }} animate={animations.eye} transition={backgroundTransition} />
        </Line>
      </Strap>
      <LogoContainer>
        <EmblemContainer to="/">
          <Emblem initial={{ opacity: 0 }} animate={animations.emblem} transition={backgroundTransition} />
        </EmblemContainer>
        <TextContainer>
          <Text.motion
            initial={{ opacity: 0 }}
            animate={animations.text}
            transition={backgroundTransition}
            uppercase
            light
            spacing={3}
          >
            Construction Inc.
          </Text.motion>
        </TextContainer>
      </LogoContainer>
      <Nav>
        {location.pathname !== '/projects' && (
          <NavItem to="/projects">
            <Text light uppercase spacing={2}>
              Projects
            </Text>
          </NavItem>
        )}
        {location.pathname !== '/' && (
          <NavItem to="/">
            <Text light uppercase spacing={2}>
              About
            </Text>
          </NavItem>
        )}
      </Nav>
    </Container>
  )
}

Header.propTypes = {
  location: PropTypes.object,
}

export default Header

const STRAP_STROKE = 1

const Nav = styled.nav(
  ({ theme }) => css`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: ${theme.space(1)};
    padding-right: ${theme.space(2)};

    ${theme.media.lg`
      padding-right: ${theme.space(11)};
    `}
  `,
)

const NavItem = styled(Link)(
  ({ theme }) => css`
    margin-left: ${theme.space(2)};
    display: block;
  `,
)

const Container = styled.header(
  ({ theme }) => css`
    width: 100%;
    height: ${rem(theme.layout.headerHeight)};
    padding-top: ${theme.space(3)};
    top: 0;
    left: 0;
    right: 0;

    display: flex;
    align-items: flex-end;
    flex-direction: column;
    grid-area: header;
    position: fixed;
    z-index: 100;

    ${theme.media.lg`

  `} //&:after {
    //  position: absolute;
    //  content: '';
    //  height: 150%;
    //  width: 100%;
    //  background: linear-gradient(180deg, rgba(0,0,0,.5) 0%, rgba(0,0,0,0) 100%);
    //  top: 0;
    //  right:0;
    //  left: 0;
    //  z-index: 1;
    //
    //}
  `,
)
const LogoContainer = styled.div(
  ({ theme }) => css`
    height: 100%;
    width: 100%;
    display: flex;
    align-items: flex-end;
    padding-left: ${theme.space(2)};

    ${theme.media.lg`
      padding-left: ${theme.space(11)};
    `}
  `,
)

const EmblemContainer = styled(Link)(
  ({ theme }) => css`
    height: 100%;
    position: relative;
    display: block;

    svg {
      z-index: 12;
      height: 95px;
    }
    > *:first-child {
    }
  `,
)

const TextContainer = styled.div(
  ({ theme }) => css`
    display: inline-block;
    z-index: 10;
    user-select: none;
    ${Text.motion} {
      padding-bottom: ${theme.space(3.3)};
      padding-left: ${theme.space(1)};
    }
  `,
)

const Strap = styled.div(
  ({ theme }) => css`
    display: flex;
    position: absolute;
    width: 100%;
    bottom: ${theme.space(1)};

    > *:first-child {
      margin-right: ${theme.space(2.1)};
      width: ${theme.space(5.5)};
      flex-shrink: 0;
      flex-grow: 0;
      padding: 0;

      ${theme.media.lg`
      margin-right: ${theme.space(2.1)};
      width: calc(${theme.space(14.5)});
    `}
    }
  `,
)

const Line = styled(motion.div)`
  ${({ theme }) => css`
    z-index: 10;
    height: ${theme.space(STRAP_STROKE)};
    background: ${theme.color.primary};
    width: auto;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0 ${theme.space(3)};
    flex-grow: 1;

    > * {
      height: ${theme.space(STRAP_STROKE * 0.8)};
    }
  `}
`
